<script>
    import appConfig from "@/app.config";
    import PageHeader from "@/components/structure/page-header";
    import Paginate from "@/components/pagination";
    import helper from "@/assets/helpers/helper";
    /**
     * Orders component
     */
    export default {
        page: {
            title: "Users",
            meta: [{name: "description", content: appConfig.description}]
        },
        name:"Users",
        components: {Paginate, PageHeader},
        data() {
            return {
                users: [],
                title: "Users",
                name: 'Users',
                none:'none',
                current_page: 1,
                last_page_url: 6,
                currentSort:'rank',
                currentSortDir:'ascending',
                filtre_username: "",
                filtre_country: "",
                isLoadedUsers:false
            };
        },

        created() {
            this.loadUsers(1)
        },
        watch: {
            filtre_username:function        ()    {this.filterSearch(1)},
            filtre_country:function     ()    {this.filterSearch(1)},

        },
        computed:{
            users_sorted:function() {
                let users = this.users;
                return users.sort((a,b) => {
                    let modifier = 1;
                    if(this.currentSortDir === 'descending') modifier = -1;
                    if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                });
            }
        },
        methods:{

            async loadUsers(page){
                let users = await helper.loadData('users', page);
                if(users) {
                  if (page > 1) {
                    this.users = Object.keys(users.data).map(function (el) {
                      let p = users.data[el];
                      return ({
                        ...p,
                        score: p.score ? parseInt(p.score) : 0,
                        rank: p.ranks.length ? p.ranks[0].rank : '-'
                      });
                    });
                  } else this.users = users.data.map(p => ({
                    ...p,
                    score: p.score ? parseInt(p.score) : 0,
                    rank: p.ranks.length ? p.ranks[0].rank : '-'
                  }))
                  this.isLoadedUsers = true
                  this.current_page = users.current_page
                  this.last_page_url = users.last_page;
                }
            },
            sort:function(s) {
                //if s == current sort, reverse
                if(s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir==='ascending'?'descending':'ascending';
                }
                this.currentSort = s;
            },
            changePage(page) {
                if(this.filtre_username == '' && this.filtre_country == '') this.loadUsers(page)
                else this.filterSearch(page)
            },
            filterSearch(page){
                this.$http
                    .post('users/search?page='+page,
                        {
                            username  : this.filtre_username,
                            country   : this.filtre_country,
                        })
                    .then(response => {
                        this.users = response.data.data.map( p => ({ ...p,score:p.score?parseInt(p.score):0,rank:p.ranks.length?p.ranks[0].rank:'-'}));
                        this.current_page  = response.data.current_page;
                        this.last_page_url = response.data.last_page;
                    })
                    .catch(error => {
                        console.log(error)

                    })
            },
            async Follow(id,data) {
                let result = await helper.postData('users/'+id+'/follow');
                if(result)
                {
                    data.is_followed_by_me = 1 - data.is_followed_by_me
                }
            },
        },

    };
</script>

<template>
    <div>
        <PageHeader :title="$t('profil_user.title_tab')"/>
        <div class="row">
            <div class="col-xl-10 col-lg-12 col-sm-12 m-auto">
                <div class="card">
                    <div class="card-body">
                        <form>
                            <div class="row mb-2">

                                <div class="col-xl-2 col-sm-3">
                                    <div class="form-group  mb-0">
                                        <label>{{$t('profil_user.username')}}</label>

                                        <b-form-input :placeholder="$t('profil_user.username_ph')" v-model="filtre_username"></b-form-input>
                                    </div>
                                </div>

                                <div class="col-xl-2 col-sm-3">
                                    <div class="form-group  mb-0">
                                        <label>{{$t('profil_user.country')}}</label>
                                        <country-select id="country" :blackList="['EH']" :autocomplete="true"  v-model="filtre_country" class="custom-select" :countryName="true" />
                                    </div>
                                </div>

                            </div>
                        </form>
                        <!-- Table -->

                            <div v-if="!isLoadedUsers">
                                <b-skeleton-table :rows="10" :columns="6"></b-skeleton-table>
                                <b-skeleton></b-skeleton>
                            </div>
                            <div class="row mt-4" v-if="isLoadedUsers && users.length">
                            <table class=" tab table b-table text-center " role="table">
                                <thead>
                                <tr>
                                    <th scope="col" @click="sort('rank')" role="columnheader"  :aria-sort="(currentSort == 'rank')?currentSortDir:none">#</th>
                                    <th scope="col" @click="sort('username')" role="columnheader" class="text-xl-left pl-xl-3" :aria-sort="(currentSort == 'username')?currentSortDir:none">{{$t('profil_user.username')}}</th>
                                    <th scope="col" @click="sort('score')" role="columnheader" :aria-sort="(currentSort == 'score')?currentSortDir:none">{{$t('profil_user.score')}}</th>
                                    <th scope="col" @click="sort('country')" role="columnheader" :aria-sort="(currentSort == 'country')?currentSortDir:none">{{$t('profil_user.country')}}</th>
                                    <th scope="col" @click="sort('challenge_solved_count')" role="columnheader" :aria-sort="(currentSort == 'challenge_solved_count')?currentSortDir:none">{{$t('profil_user.solves')}}</th>
                                    <th scope="col" ></th>

                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="data of users_sorted" :key="data.id">
                                    <td data-label="#">{{data.rank?data.rank:'-'}}</td>
                                    <td :data-label="$t('profil_user.username')" class="text-xl-left">
                                        <b-avatar :src="data.avatar" class="mr-3"></b-avatar>
                                       <router-link :to="{name:'ProfilUser',params:{id:data.user_id}}" class="h6 link"> {{data.username}}</router-link>
                                    </td>
                                    <td :data-label="$t('profil_user.score')"> {{data.score?data.score:0}}</td>
                                    <td :data-label="$t('profil_user.country')"> {{data.country?data.country:'-'}}</td>
                                    <td :data-label="$t('profil_user.solves')">{{data.challenge_solved_count}}</td>
                                    <td :data-label="$t('profil_comp.actions')">
                                      <button type="button" class="btn btn-outline-primary mr-1 btn-sm btn-rounded" @click="Follow(data.user_id,data)" v-if="$store.state.user.user_id !== data.user_id">{{data.is_followed_by_me === 0?$t('profil_user.follow'):$t('profil_user.unfollow')}}</button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                                    <paginate class="mx-auto my-3" :current_page=current_page :last_page_url=last_page_url
                                              v-on:change-page="changePage"/>
                            </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>